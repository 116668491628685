import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple } from "../components"

export default ({ data, pageContext }) => {
  const { stories_title, stories_text, stories_author } = data.airtable.data

  const { previous, next } = pageContext

  const prevTitle = previous ? previous.data.stories_title : null
  const nextTitle = next ? next.data.stories_title : null
  const prevRow = previous ? previous.data.stories_row : null
  const nextRow = next ? next.data.stories_row : null
  const allItemPage = "/library/stories/1"
  const oneItemPage = "/library/story/"

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h1>{stories_author}</h1>
          <h2>{stories_title}</h2>

          <div
            className="info"
            dangerouslySetInnerHTML={{
              __html: stories_text.childMarkdownRemark.html,
            }}
          ></div>
        </div>

        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-light-10);
  padding-top: 9rem;
  padding-bottom: 0rem;

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    h2 {
      text-align: center;
      padding-bottom: 2rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }

    .info {
      padding-left: 10%;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }
  @media (min-width: 768px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 40%;
    }
  }
`

export const query = graphql`
  query($recordId: String!) {
    airtable(recordId: { eq: $recordId }) {
      id
      recordId
      data {
        stories_title
        stories_author
        stories_row
        stories_text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
